export default {
  state: {
    // hl_urldentisalud: "https://localhost:44389/api/",
    // hl_urldentisalud: "https://localhost:44388/api/",
    hl_urldentisalud: "https://dentisaludbackend.helexiumdataia.com/api/",
  },
  actions: {
    async denti_get(context, { path }) {
      try {
        console.log("get dentisalud--->");
        const token = localStorage.getItem("tk");

        if (!token) {
          throw new Error(
            "Token no encontrado. Por favor, inicia sesión nuevamente."
          );
        }

        // Configuración de opciones para la solicitud
        const options = {
          method: "GET",
          headers: {
            // Authorization: `Bearer ${token}`,
          },
        };

        // Función para manejar el timeout
        const fetchWithTimeout = (url, options, timeout = 150000) => {
          return new Promise((resolve, reject) => {
            const timer = setTimeout(() => {
              reject(new Error("Request timed out"));
            }, timeout);

            fetch(url, options)
              .then((response) => {
                clearTimeout(timer);
                resolve(response);
              })
              .catch((error) => {
                clearTimeout(timer);
                reject(error);
              });
          });
        };

        // Llamada a la API con un timeout
        const res = await fetchWithTimeout(
          context.state.hl_urldentisalud + path,
          options,
          150000
        );

        // Procesar la respuesta
        if (!res.ok) {
          // Si la respuesta HTTP no es 200-299, lanza un error
          throw new Error(`Error HTTP: ${res.status} - ${res.statusText}`);
        }

        const req = await res.json(); // Procesa la respuesta en formato JSON

        // Verificar errores específicos en la respuesta
        if (req.mensaje === "Token inválida") {
          localStorage.removeItem("tk");
          // router.push('/'); // Descomentar si necesitas redirigir al usuario
          throw new Error(
            "Sesión inválida. Por favor, inicia sesión nuevamente."
          );
        }

        if (req.error) {
          console.error(req.error);
          throw new Error(req.mensaje || "Ocurrió un error desconocido.");
        }

        return req; // Retorna la respuesta procesada
      } catch (error) {
        console.error("Error en denti_get:", error.message || error);
        // Puedes manejar el error globalmente aquí si es necesario
        throw error; // Re-lanzar el error para manejarlo desde donde se llama esta función
      }
    },

    // async denti_get(context, { path }) {
    //     try {
    //         console.log('get dentisalud--->')
    //         let token = localStorage.getItem("tk");
    //         const options = {
    //             method: "GET",
    //             headers: {
    //                 Authorization: "Bearer " + token,
    //             },
    //         };
    //         let res = await fetch(context.state.hl_urldentisalud + path, options);
    //         // console.log("get", res);
    //         let req = await res.json(); // await JSON.parse(res)
    //         if (res.mensaje == "Token inválida") {
    //             //router.push('/')
    //         }
    //         if (res.error) {
    //             console.error(res.error);
    //             alert(res.mensaje);
    //         } else {
    //             return req;
    //         }
    //     } catch (error) {
    //         console.error("error", error);
    //         return error;
    //     }
    // },
    async denti_post(context, { path, data }) {
      try {
        let token = localStorage.getItem("tk");
        const options = {
          method: "post",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
        let req = await fetch(context.state.hl_urldentisalud + path, options);
        let res = await req.json();
        if (res.mensaje == "Token inválida") {
          //router.push('/')
        }
        if (res.error) {
          alert(res.error);
        } else {
          return res;
        }
      } catch (error) {
        console.error("error---------->", error);
        return error;
      }
    },
    async denti_put(context, { path, data }) {
      try {
        let token = localStorage.getItem("tk");
        const options = {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
        let req = await fetch(context.state.hl_urldentisalud + path, options);
        let res = await req.json();
        if (res.mensaje === "Token inválida") {
          console.warn("Token inválido");
        }
        if (res.error) {
          alert(res.error);
        } else {
          return res;
        }
      } catch (error) {
        console.error("Error en la solicitud PUT:", error);
        return { error: error.message };
      }
    },
    async denti_delete(context, { path, data }) {
      try {
        let token = localStorage.getItem("tk");
        const options = {
          method: "delete",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
        let req = await fetch(context.state.hl_urldentisalud + path, options);
        let res = await req.json();
        if (res.mensaje == "Token inválida") {
          //router.push('/')
        }
        if (res.error) {
          alert(res.error);
        } else {
          return res;
        }
      } catch (error) {
        console.error("error---------->", error);
        return error;
      }
    },
  },
};
